<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact
      >Historia Clinica - {{ name_historia }}</page-header-compact
    >
    <br />
    <div class="container-xl px-4">
      <div class="card">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <br />
      <wizard
        :current_step="current"
        :steps="steps.length"
        @next="next($event)"
        @prev="prev($event)"
      >
        <template #tabs>
          <wizard-tab
            id="step1"
            active="true"
            step="1"
            title="Interrupción del embarazo"
            v-on:tab-click="next({ prev: current, current: 1 })"
          ></wizard-tab>
          <wizard-tab
            id="step2"
            step="2"
            title="Motivo Consulta"
            v-on:tab-click="next({ prev: current, current: 2 })"
          ></wizard-tab>
          <wizard-tab
            id="step3"
            step="3"
            title="Embarazo Actual"
            v-on:tab-click="next({ prev: current, current: 3 })"
          ></wizard-tab>
          <wizard-tab
            id="step4"
            step="4"
            title="Revisión por sistemas"
            v-on:tab-click="next({ prev: current, current: 4 })"
          >
          </wizard-tab>
          <wizard-tab
            id="step5"
            step="5"
            title="Examen Físico"
            v-on:tab-click="next({ prev: current, current: 5 })"
          ></wizard-tab>
          <wizard-tab
            id="step6"
            step="6"
            title="Graficas"
            v-on:tab-click="next({ prev: current, current: 6 })"
          ></wizard-tab>

          <wizard-tab
            id="step7"
            step="7"
            title="Vacunas"
            v-on:tab-click="next({ prev: current, current: 7 })"
          ></wizard-tab>
          <wizard-tab
            id="step8"
            step="8"
            title="Evaluación psicosocial"
            v-on:tab-click="next({ prev: current, current: 8 })"
          ></wizard-tab>
          <wizard-tab
            id="step9"
            step="9"
            title="Riesgo Preeclampsia"
            v-on:tab-click="next({ prev: current, current: 9 })"
          ></wizard-tab>
          <wizard-tab
            id="step10"
            step="10"
            title="Suministro de ASA"
            v-on:tab-click="next({ prev: current, current: 10 })"
          ></wizard-tab>
          <wizard-tab
            id="step11"
            step="11"
            title="Laboratorios"
            v-on:tab-click="next({ prev: current, current: 11 })"
          ></wizard-tab>
          <wizard-tab
            id="step12"
            step="12"
            title="Ordenes"
            v-on:tab-click="next({ prev: current, current: 12 })"
          ></wizard-tab>
          <wizard-tab
            id="step13"
            step="13"
            title="Formulación"
            v-on:tab-click="next({ prev: current, current: 13 })"
          ></wizard-tab>
          <wizard-tab
            id="step14"
            step="14"
            title="Impresión Diagnostica"
            v-on:tab-click="next({ prev: current, current: 14 })"
          ></wizard-tab>
          <wizard-tab
            title="Plan de Intervención"
            step="15"
            id="step15"
            v-on:tab-click="next({ prev: current, current: 15 })"
          ></wizard-tab>
          <wizard-tab
            title="Recomendaciones"
            step="16"
            id="step16"
            v-on:tab-click="next({ prev: current, current: 16 })"
          ></wizard-tab>
          <wizard-tab
            id="step17"
            step="17"
            title="Resumen"
            v-on:tab-click="next({ prev: current, current: 17 })"
          ></wizard-tab>
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                :is="currentComponent"
                :ref="currentComponent"
                v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <div class="row">
            <remision-component
              :idHistoria="idHistoria"
              :usuario="usuario"
            ></remision-component>
            <incapacidad-component
              :idHistoria="idHistoria"
              :usuario="usuario"
            ></incapacidad-component>
            <finalizar-historia
              :id-usuario="usuario.id"
              :id-historia="idHistoria"
              :codigoHistoria="'EMB'"
            ></finalizar-historia>
          </div>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>
import Wizard from "../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
import examenFisicoComponent from "../base/examenFisico/examenFisico";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../services/historiaClinicaService";
import ordenComponent from "../base/orden/ordenComponent";
import formulaMedicaComponent from "../base/formulaMedica/formulaMedicaComponent";
import impresionDXComponent from "../base/impresionDX/impresionDXComponent";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import controlComponent from "./controlComponent";
import embarazoActualComponent from "../base/baseEmbarazoActual/indexEmbarazoActual";
import resumenHcComponent from "./resumenHc/resumenHcComponent";
import revisionSistemas from "@/views/historias/base/baseUrgencia/revision";
import recomendaciones from "@/views/historias/base/recomendaciones/recomendaciones";
import intervenciones from "@/views/historias/base/intervenciones/intervenciones";
import indexVacunas from "@/views/historias/base/baseVacunas/index/indexVacunas";
import FinalizarHistoria from "@/views/historias/base/finalizarHistoria/finalizarHistoria";
import RemisionComponent from "../base/baseRemision/index";
import LaboratoriosIndex from "./laboratoriosIndex";
import formularioComponent from "../base/baseRespuestaFormularios/index";
import riesgoPreeclampsia from "../base/baseRespuestaFormularios/index";
import graficas from "./graficas";
import incapacidadComponent from "../base/baseIncapacidades/index";
import formularioAsa from "../base/baseRespuestaFormularios/index";

export default {
  components: {
    FinalizarHistoria,
    PageHeaderCompact,
    motivoConsultaComponent,
    examenFisicoComponent,
    WizardTabPane,
    WizardTab,
    Wizard,
    ordenComponent,
    formulaMedicaComponent,
    impresionDXComponent,
    modalInformacionUsuario,
    cardInformacionUsuario,
    controlComponent,
    embarazoActualComponent,
    resumenHcComponent,
    revisionSistemas,
    recomendaciones,
    intervenciones,
    indexVacunas,
    RemisionComponent,
    LaboratoriosIndex,
    formularioComponent,
    graficas,
    incapacidadComponent,
    riesgoPreeclampsia,
    formularioAsa,
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      usuario: {},
      current: 1,
      checkpoint: 1,
      idTipoHistoria: "",
      steps: [
        { stepId: "#step1", ref: "formularioComponent", validate: true },
        { stepId: "#step2", ref: "motivoConsultaComponent", validate: true },
        { stepId: "#step3", ref: "embarazoActualComponent", validate: true },
        { stepId: "#step4", ref: "revisionSistemas", validate: true },
        { stepId: "#step5", ref: "examenFisicoComponent", validate: true },
        { stepId: "#step6", ref: "graficas", validate: false },
        { stepId: "#step7", ref: "indexVacunas", validate: false },
        { stepId: "#step8", ref: "controlComponent", validate: false },
        { stepId: "#step9", ref: "riesgoPreeclampsia", validate: true },
        { stepId: "#step10", ref: "formularioAsa", validate: true },
        { stepId: "#step11", ref: "LaboratoriosIndex", validate: false },
        { stepId: "#step12", ref: "ordenComponent", validate: false },
        { stepId: "#step13", ref: "formulaMedicaComponent", validate: false },
        { stepId: "#step14", ref: "impresionDXComponent", validate: true },
        { stepId: "#step15", ref: "intervenciones", validate: false },
        { stepId: "#step16", ref: "recomendaciones", validate: false },
        { stepId: "#step17", ref: "resumenHcComponent", validate: true },
      ],
      name_historia: "",
    };
  },
  methods: {
    prev(step) {
      this.current = step.current;
      this.activeTab();
    },
    async next(step) {
      if (step.prev <= this.checkpoint && step.current - 1 > step.prev) {
        this.activeTab();
        return;
      }

      const refComponent = this.steps[step.prev - 1].ref;

      if (typeof this.$refs[refComponent].save !== "undefined") {
        await this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if (step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      const response = await historiaClinicaService.show(id);

      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;
      this.current = response.data.checkpoint;
      this.checkpoint = response.data.checkpoint;
      this.idTipoHistoria = response.data.id_tipo_historia;
      this.name_historia = response.data.tipo_historia.nombre;

      this.activeTab();
    },
    activeTab() {
      document.querySelectorAll(`a.active`).forEach((a) => {
        a.classList.remove("active");
      });
      document
        .querySelector(`a[href='${this.steps[this.current - 1].stepId}']`)
        .classList.add("active");
    },
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {
      if (this.currentComponent === "formularioComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          codigo: "I_VOL_EMB",
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "riesgoPreeclampsia") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          codigo: "PRECLAM",
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "motivoConsultaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
        };
      }

      if (this.currentComponent === "examenFisicoComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          modo: "EMBARAZO",
          codigo: "EMB",
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "graficas") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
        };
      }

      if (this.currentComponent === "revisionSistemas") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          sexo: this.usuario.sexo,
        };
      }

      if (this.currentComponent === "embarazoActualComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
        };
      }

      if (this.currentComponent === "controlComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          codigo: "EXP_VIOL",
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "ordenComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "formulaMedicaComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
        };
      }

      if (this.currentComponent === "impresionDXComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        };
      }

      if (this.currentComponent === "resumenHcComponent") {
        return {
          idHistoria: this.idHistoria,
          idUsuario: this.usuario.id,
        };
      }

      if (this.currentComponent === "intervenciones") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "recomendaciones") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "indexVacunas") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
        };
      }

      if (this.currentComponent === "LaboratoriosIndex") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario,
          idUsuario: this.usuario.id,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent === "formularioAsa") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          codigo: "ASA",
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      return {};
    },
    currentComponent() {
      let components = [
        "formularioComponent",
        "motivoConsultaComponent",
        "embarazoActualComponent",
        "revisionSistemas",
        "examenFisicoComponent",
        "graficas",
        "indexVacunas",
        "controlComponent",
        "riesgoPreeclampsia",
        "formularioAsa",
        "LaboratoriosIndex",
        "ordenComponent",
        "formulaMedicaComponent",
        "impresionDXComponent",
        "intervenciones",
        "recomendaciones",
        "resumenHcComponent",
      ];

      return components[this.current - 1];
    },
  },
};
</script>

<style scoped></style>
