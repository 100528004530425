<template>
    <main>
        <canvas id="graficoPresion" class="canvas-h canvas-w"></canvas>
        <div class="form-group">
            <span class="font-weight-bolder">Observaciones </span>
            <textarea class="form-control mt-3"  cols="10" rows="5" v-model="observaciones"></textarea>
        </div>
    </main>
</template>
  
<script>
import Chart from 'chart.js/auto';

export default {
    props: ['data_chart', 'label','observacionesGraphs'],
    data() {
        return {
            graficoPresion: '',
            observaciones: this.observacionesGraphs,
        }
    },
    methods: {
        open() {

            if (this.graficoPresion !== '') {
                this.graficoPresion.destroy();
            }

            var grafico_presion_arterial = document.getElementById('graficoPresion').getContext("2d");

            let label = [];
            let pa_sistolica = [];
            let pa_diastolica = [];

            this.data_chart.forEach((item, y) => {
                label[y] = item.fecha;
                pa_sistolica[y] = item.pa_sistolica;
                pa_diastolica[y] = item.pa_diastolica;
            });

            this.graficoPresion = new Chart(grafico_presion_arterial, {
                type: 'line',
                data: {
                    labels: label,
                    datasets: [
                        {
                            label: 'Presión Arterial Sistólica',
                            data: pa_sistolica,
                            fill: false,
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: [
                                'rgb(255, 99, 132)'
                            ],
                            borderWidth: 2,
                            hoverBorderWidth: 0,
                            tension: 0.1,
                            pointBackgroundColor: 'rgb(255, 99, 132)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'rgb(255, 99, 132)',
                            pointHoverBorderColor: 'rgb(255, 99, 132)',
                        },
                        {
                            label: 'Presión Arterial Diastólica',
                            data: pa_diastolica,
                            fill: false,
                            borderColor: 'rgb(54, 162, 235)',
                            backgroundColor: [
                                'rgb(54, 162, 235)'
                            ],
                            borderWidth: 2,
                            hoverBorderWidth: 0,
                            tension: 0.1,
                            pointBackgroundColor: 'rgb(54, 162, 235)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: 'rgb(54, 162, 235)',
                            pointHoverBorderColor: 'rgb(54, 162, 235)',
                        },
                    ],
                },
                options: {
                    elements: {
                        line: {
                            borderWidth: 1
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                font: {
                                    size: 15
                                }
                            },
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Fecha'
                            },
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Presión Arterial (mmHg)'
                            },
                            suggestedMin: 0,
                        }
                    }
                }
            });
        },
        export() {
            const canva = document.getElementById('graficoPresion');
            const urlData = canva.toDataURL('image/png', 0.8);
            return {
                chart: 'Presion Arterial',
                imageData: urlData,
                observaciones: this.observaciones,
                codigo:'presionArterial'
            };
        }
    },
    watch: {
        data_chart: {
            handler: function () {
                this.$nextTick(() => this.open());
            },
            deep: true
        },
        observacionesGraphs: async function (value) {
            if (value === "" || value === undefined || value === null) {
                this.observaciones = "";
                return;
            }
            this.observaciones = value;
        },
    },
    
}

</script>