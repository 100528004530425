import Service from "./Service";
const baseurl = '/api/embarazo-actual';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByLast(id) {
        return Service.get(`${baseurl}/show-by-last/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    showByHc(id) {
        return Service.get(`${baseurl}/show-by-hc/${id}`);
    },
}