<template>
    <main>
       <div v-for="item in preguntas" :key="item.id">
            <label class="font-weight-bold">{{item.nombre}}</label>
            <table class="table table-striped table-bordered table-sm" >
                <tr class="bg-light">
                    <th>Pregunta</th>
                    <th>Opciones</th>
                </tr>
                <tr v-for="pre in item.pregunta" :key="pre.id">
                    <td class="">{{pre.texto}}</td>
                    <td>
                        <select class="form-control form-control-sm" v-model="pre.respuesta">
                            <option v-for="opc in pre.opciones" :key="`${opc.id}_opc`" :value="opc.valor">
                                {{opc.texto}}
                            </option>
                        </select>
                    </td>
                </tr>
            </table>
        </div>
        <div class="mt-4 text-center d-flex justify-content-center ">
            <div class="col-5">
                <div :class="`card ${clasificacion.class} shadow-none`">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                                <div class="h3 fw-bold text-bold mb-1">Puntaje Total: <span class="badge badge-white text-bold h2">{{ puntaje }}</span></div>
                                <div class="h5 text-black-75">{{clasificacion.text}}</div>
                                <input type="hidden" class="form-control" v-model="puntaje" readonly>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <!-- <div class="form-group col-12">
               <label class="font-weight-bold">Puntaje Total</label>
               <input type="hidden" class="form-control" v-model="puntaje" readonly>
               <span :class="clasificacion.class">{{clasificacion.text}}</span>
           </div> -->
       </div>
    </main>
</template>
<script>
import usuarioService from '../../../../../services/usuarioService';
import tipoRiesgoBiopsicosocialService from '../../../../../services/tipoRiesgoBiopsicosocialService';
import valoracionRiesgoBiopsicosocialService from "../../../../../services/valoracionRiesgoBiopsicosocialService";
import {isEmpty} from "lodash";
import Toast from "@/components/common/utilities/toast";
import embarazoService from '@/services/embarazoService';
import embarazoActualService from '@/services/embarazoActualService';
export default {
    props: ['idHistoria','finalizada','idUsuario','trimestre'],
    data() {
        return {
            usuario: {},
            preguntas: [],
            id_valoracion:''
        };
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarPreguntas(){
            this.LoaderSpinnerShow();

            const res_valoracion = await valoracionRiesgoBiopsicosocialService.showByHc(
                {
                    id_historia:this.idHistoria,
                    trimestre:this.trimestre
                }
            );
    
            let response_last=null;

            if(!isEmpty(res_valoracion.data)) {
                this.id_valoracion=res_valoracion.data.id;
            }else{

                const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);

                if(!isEmpty(res_embarazo_actual.data)){
                    const response=await embarazoActualService.showByHc(this.idHistoria);
                    if(response.data.id_embarazo===res_embarazo_actual.data.id){
                        response_last = await valoracionRiesgoBiopsicosocialService.showLast({
                            id_usuario:this.idUsuario,
                            trimestre:this.trimestre,
                            id_embarazo:response.data.id_embarazo,
                            idHistoria:this.idHistoria
                        });
                    }
                }
            }


            const response = await tipoRiesgoBiopsicosocialService.index();
            this.preguntas = response.data.map(p => {
               
                return {
                    ...p,
                    pregunta : p.pregunta.map(x => {
                        let respuesta='';
                        if(!isEmpty(res_valoracion.data)) {
                            respuesta=res_valoracion.data.valoracion_detalles.find(res=>parseInt(res.id_pregunta)===parseInt(x.id))?.valor || 0

                        }else if(response_last && response_last.data && response_last.data.valoracion_detalles){
                            respuesta=response_last.data.valoracion_detalles.find(res=>parseInt(res.id_pregunta)===parseInt(x.id))?.valor || 0
                        }

                        return {
                            ...x,
                            respuesta : respuesta
                        }
                        
                    })
                }
            });
            this.LoaderSpinnerHide();
        },
        async save() {
            try {
                let respuestas=[];

                this.preguntas.forEach(x=>{
                    respuestas.push(...x.pregunta);
                });

                let valoracion_riesgos = {
                    puntaje_total:this.puntaje,
                    id_historia: this.idHistoria,
                    respuestas: respuestas,
                    trimestre:this.trimestre
                };

                if(this.id_valoracion!==''){
                    valoracion_riesgos.id=this.id_valoracion;
                    await valoracionRiesgoBiopsicosocialService.update(valoracion_riesgos);
                }else{
                    await valoracionRiesgoBiopsicosocialService.store(valoracion_riesgos);
                }

              Toast.fire({
                icon: 'success',
                title: 'Escala biopsicosocial guardada con exíto'
              });

            } catch (e) {
                console.error(e);
                Toast.fire({
                  icon: 'error',
                  title: 'Ocurrio un error al guardar la escala biopsicosocial'
                });
            }
        },
    },
    async created() {
        await this.cargarUsuario(this.idUsuario);
        this.cargarPreguntas();
    },
    watch: {
        trimestre: async function (value){ 
            if(value){
                this.id_valoracion='';
                this.cargarPreguntas();                
            }
        }
    },
    computed : {
        puntaje : function(){
            
            let total  = 0
            
            this.preguntas.filter(x => x.grupo === 'NO').forEach(seccion => {
                total += seccion.pregunta.reduce((t,c) => {
                   if(c.respuesta === '') return Number(t);
                   return Number(t) + Number(c.respuesta);
                },0)
            });


            this.preguntas.filter(x => x.grupo === 'SI').forEach(seccion => {
                
                
                let grupos = [];

                seccion.pregunta.forEach(c => {
                    if(!grupos.includes(c.grupo)) grupos.push(c.grupo);
                }); 
                
                grupos.forEach(g => {

                    let mayor_valor = '';

                    seccion.pregunta.filter(p => p.grupo === g).forEach(pregunta => {
                        mayor_valor = Math.max(...pregunta.opciones.map(op => Number(op.valor)));
                    });

                    total += seccion.pregunta.filter(x => {
                        return (Number(x.respuesta) === Number(mayor_valor)) && x.grupo === g
                    }).length >= 2
                    ? 1
                    : 0;
                });


            });

            return total;

        },
        clasificacion(){

            const clasifications = {
                'bajo' : {
                    code : 'bajo',
                    text  : 'Bajo riesgo biopsicosocial',
                    class : 'bg-success-soft'
                },
                'alto' : {
                    code : 'alto',
                    text  : 'Alto riesgo biopsicosocial',
                    class : 'bg-danger-soft'
                }
            };

            return this.puntaje > 3 ? clasifications['alto'] : clasifications['bajo'];

        }
    }
}
</script>