<template>
  <main>
    <canvas id="graficoAltura" class="canvas-h canvas-w"></canvas>
    <div class="form-group">
      <span class="font-weight-bolder">Observaciones </span>
      <textarea
        class="form-control mt-3"
        cols="10"
        rows="5"
        v-model="observaciones"
      ></textarea>
    </div>
  </main>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  props: ["data_chart", "label", "code", "observacionesGraphs"],
  data() {
    return {
      graficoAltura: "",
      observaciones: this.observacionesGraphs
    };
  },
  methods: {
    open() {
      if (this.graficoAltura !== "") {
        this.graficoAltura.destroy();
      }

      var grafico_altura_uterina = document
        .getElementById("graficoAltura")
        .getContext("2d");

      let label = [];
      let altura = [];

      this.data_chart.forEach((item, y) => {
        label[y] = item.fecha;
        altura[y] = item.altura;
      });

      this.graficoAltura = new Chart(grafico_altura_uterina, {
        type: "line",
        data: {
          labels: label,
          datasets: [
            {
              label: this.label,
              data: altura,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              backgroundColor: [
                "#0061F2",
                "#6900C7",
                "#FEC542",
                "#00BA94",
                "#00d2ff",
              ],
              borderWidth: 2,
              hoverBorderWidth: 0,
              tension: 0.1,
              pointBackgroundColor: "rgb(26, 168, 0)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(26, 168, 0)",
            },
          ],
        },
        options: {
          elements: {
            line: {
              borderWidth: 1,
            },
          },
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                font: {
                  size: 15,
                },
              },
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Fecha",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Altura (CM)",
              },
              suggestedMin: 0,
            },
          },
        },
      });
    },
    export() {
      const canva = document.getElementById("graficoAltura");
      const urlData = canva.toDataURL("image/png", 0.8);
      return {
        chart: "Altura Uterina",
        imageData: urlData,
        observaciones: this.observaciones,
        codigo: "alturaUterina",
      };
    },
  },
  watch: {
    data_chart: {
      handler: function () {
        this.$nextTick(() => this.open());
      },
      deep: true,
    },
    observacionesGraphs: async function (value) {
      if (value === "" || value === undefined || value === null) {
          this.observaciones = "";
          return;
      }
      this.observaciones = value;
    },
  },
};
</script>
